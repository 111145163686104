<template>
  <nav class="t-menu flex__e__t">
    <ul class="ra flex__m tc">
      <li>
        <router-link to="/lms" class="block">
          <div class="flex__d__m">
            <div class="rel">
              <span class="material-symbols-outlined">home</span>
            </div>
            <p>HOME</p>
          </div>
        </router-link>
      </li>
      <!-- 임시 삭제 -->
      <!-- <li>
        <router-link to="/lms/notification" class="block">
          <div class="flex__d__m">
            <div class="rel">
              <span class="material-symbols-outlined">notifications</span>
              <i class="abs block tc">1</i>
            </div>
            <p>알림</p>
          </div>
        </router-link>
      </li> -->
      <!-- <li>
        <router-link to="/lms/message" class="block">
          <div class="flex__d__m">
            <div class="rel">
              <span class="material-symbols-outlined">mail</span>
              <i class="abs block tc">2</i>
            </div>
            <p>쪽지</p>
          </div>
        </router-link>
      </li> -->
      <li>
        <router-link to="/lms/mypage" class="block rel">
          <div class="flex__d__m">
            <div>
              <span class="material-symbols-outlined">account_circle</span>
            </div>
            <p>마이페이지</p>
          </div>
        </router-link>
      </li>
      <li class="guide rel t-t-m-non" @mouseover="toggleGuide(true)" @mouseleave="toggleGuide(false)" :class="{ 'show': isGuideShown }">
        <div class="rel">
          <div class="flex__d__m">
            <div>
              <span class="material-symbols-outlined">help</span>
            </div>
            <p>매뉴얼</p>
          </div>          
        </div>
        <ul class="list abs">
          <li><a href="/document/pdf/UserManual_stdt.pdf" download>학습자</a></li>
          <li><a href="/document/pdf/UserManual_prf.pdf" download>교수자</a></li>
          <li><a href="/document/pdf/UserManual_staff.pdf" download>교직원</a></li>                      
        </ul>
      </li>
      <li class="logout">
        <button type="button" class="flex__c__m" @click="Logout()">
          <span class="material-symbols-outlined">logout</span>
          <span>로그아웃</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref } from 'vue';
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

const { cookies } = useCookies();
const store = useStore();

const isGuideShown = ref(false);

function Logout() {
  cookies.remove("userInfo")
  store.dispatch('openPopup', {id: 'popupLogout', data: '/'})
}

function toggleGuide(show) {
  isGuideShown.value = show;
}
</script>
import { getAuthAPI } from "@/api";
import MainPage from "@/components/main/home/component/mainContainer.vue";
import SubPage from "@/components/main/page/SubPage.vue";
import viewLmsApp from "@/view/lms/lmsApp.vue";
import viewMain from "@/view/main/main.vue";
import { createRouter, createWebHistory } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

const routes = [
  /* 기본 페이지 - 메인 */
  {
    path: "/",
    name: "viewMain",
    component: viewMain,
    children: [
      {
        path: "/",
        name: "MainPage",
        component: MainPage,
        children: [
          {
            path: "",
            name: "Home",
            component: () => import("@/components/main/home/UserHome.vue"),
          },
          {
            path: "/page",
            name: "SubPage",
            component: SubPage,
            children: [
              {
                path: "greeting",
                name: "greeting",
                component: () =>
                  import("@/components/main/comp/GreetingComp.vue"),
              },
              {
                path: "introduction",
                name: "introduction",
                component: () =>
                  import("@/components/main/comp/IntroductionComp.vue"),
              },
              {
                path: "performance-indicator",
                name: "performance-indicator",
                component: () =>
                  import("@/components/main/comp/PerformanceIndicatorComp.vue"),
              },
              {
                path: "programs",
                name: "programs",
                component: () =>
                  import("@/components/main/comp/ProgramsComp.vue"),
              },
              {
                path: "guide",
                name: "guide",
                component: () =>
                  import("@/components/main/comp/CompletionGuideComp.vue"),
              },
              {
                path: "digital-badges",
                name: "digital-badges",
                component: () =>
                  import("@/components/main/comp/DigitalBadgesComp.vue"),
              },
              {
                path: "issuance-process",
                name: "issuance-process",
                component: () =>
                  import(
                    "@/components/main/comp/DigitalBadgeIssuanceProcessComp.vue"
                  ),
              },
              {
                path: "verification",
                name: "verification",
                component: () =>
                  import(
                    "@/components/main/comp/VerificationOfAuthenticityComp.vue"
                  ),
              },
              {
                path: "notice",
                name: "notice",
                component: () =>
                  import("@/components/main/comp/NoticeComp.vue"),
              },
              {
                path: "notice/view/:noticeId",
                name: "notice-view",
                component: () =>
                  import("@/components/main/comp/NoticeViewComp.vue"),
              },
              {
                path: "qna",
                name: "qna",
                component: () => import("@/components/main/comp/QnaComp.vue"),
              },
              {
                path: "qna/view/:qnaId",
                name: "qna-view",
                component: () =>
                  import("@/components/main/comp/QnaViewComp.vue"),
              },
              {
                path: "data-room",
                name: "data-room",
                component: () =>
                  import("@/components/main/comp/DataRoomComp.vue"),
              },
              {
                path: "data-room/view/:archivesId",
                name: "data-room-view",
                component: () =>
                  import("@/components/main/comp/DataRoomViewComp.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("@/components/main/page/LoginPage.vue"),
      },
    ],
  },
  // ----------------------LMS-----------------------
  {
    path: "/lms",
    name: "LMS",
    component: viewLmsApp,
    meta: {
      roles: [
        "ROLE_ADMIN",
        "ROLE_PROFESSOR",
        "ROLE_STUDENT",
        "ROLE_SUPER_ADMIN",
        "ROLE_SUPER",
      ],
    },
    children: [
      {
        path: "",
        name: "메인페이지",
        component: () => import("@/components/lms/common/ContainerComp.vue"),
        meta: {
          roles: [
            "ROLE_ADMIN",
            "ROLE_PROFESSOR",
            "ROLE_STUDENT",
            "ROLE_SUPER_ADMIN",
            "ROLE_SUPER",
          ],
        },
        children: [
          {
            path: "",
            name: "대시보드",
            component: () => import("@/components/lms/main/lmsMain.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_STUDENT",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
        ],
      },
      {
        path: "my-exam/taking/:lectureId/:examId",
        name: "시험 - 응시",
        component: () => import("@/components/lms/sub/exam/MyExamTaking.vue"),
        meta: {
          roles: ["ROLE_STUDENT"],
          fullscreen: true,
        },
        children: [
          {
            path: "",
            name: "시험 - 응시 / 동의",
            component: () => import("@/components/lms/sub/exam/ExamAgree.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "questions",
            name: "시험 - 응시 / 문제풀이",
            component: () =>
              import("@/components/lms/sub/exam/ExamQuestions.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
        ],
      },
      {
        path: "",
        name: "서브페이지",
        component: () => import("@/components/lms/common/SubContainerComp.vue"),
        meta: {
          roles: [
            "ROLE_ADMIN",
            "ROLE_PROFESSOR",
            "ROLE_STUDENT",
            "ROLE_SUPER_ADMIN",
            "ROLE_SUPER",
          ],
        },
        children: [
          {
            path: "mypage",
            name: "마이페이지",
            component: () => import("@/components/lms/my/MyPage.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_STUDENT",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "notification",
            name: "알림",
            component: () => import("@/components/lms/sub/ReadyComp.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_STUDENT",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "message",
            name: "쪽지 - 받은 쪽지함 목록",
            component: () => import("@/components/lms/my/InBox.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_STUDENT",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "message/sent",
            name: "쪽지 - 보낸 쪽지함 목록",
            component: () => import("@/components/lms/my/SentItem.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_STUDENT",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "list",
            name: "강의 - 수강신청 - 강의목록",
            component: () =>
              import("@/components/lms/sub/mycourse/CourseReg.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "list/view/:lectureId",
            name: "강의 - 수강신청 - 강의상세",
            component: () =>
              import("@/components/lms/sub/mycourse/CourseRegView.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "myclass",
            name: "강의 - 내 강의실",
            component: () =>
              import("@/components/lms/sub/mycourse/MyClass.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          // {
          // 	path: 'myclass/view/:lectureId',
          // 	name: '강의 - 내 강의실 - 강의상세',
          // 	component: () => import('@/components/lms/sub/mycourse/MyClassView.vue'),
          // 	meta: {
          // 		roles : ['ROLE_STUDENT']
          // 	},
          // },
          // {
          // 	path: 'myclass/questions',
          // 	name: '강의 - 내 강의실 - 온라인 시험',
          // 	component: () => import('@/components/lms/sub/mycourse/MyClassQtn.vue'),
          // 	meta: {
          // 		roles : ['ROLE_STUDENT']
          // 	}
          // },
          // {
          // 	path: 'myclass/survey',
          // 	name: '강의 - 내 강의실 - 설문',
          // 	component: () => import('@/components/lms/sub/mycourse/MyClassSurvey.vue'),
          // 	meta: {
          // 		roles : ['ROLE_STUDENT']
          // 	}
          // },
          {
            path: "myclass/info",
            name: "강의 - 내 강의실 - 과목정보",
            component: () =>
              import("@/components/lms/sub/mycourse/MyClassSubject.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "myclass/lecture",
            name: "강의 - 내 강의실 - 강의정보",
            component: () =>
              import("@/components/lms/sub/mycourse/MyClassLecture.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "myclass/weekly",
            name: "강의 - 내 강의실 - 부차별 강의상세",
            component: () =>
              import("@/components/lms/sub/mycourse/MyClassWeekly.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "myclass/attn",
            name: "강의 - 내 강의실 - 출석현황",
            component: () =>
              import("@/components/lms/sub/mycourse/MyClassAttn.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "myclass/notice",
            name: "강의 - 강의 공지사항",
            component: () =>
              import("@/components/lms/sub/mycourse/MyNoticeList.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "myclass/notice/view",
            name: "강의 - 강의 공지사항 - 상세",
            component: () =>
              import("@/components/lms/sub/mycourse/MyNoticeView.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "myclass/notice/write",
            name: "강의 - 강의 공지사항 - 쓰기",
            component: () =>
              import("@/components/lms/sub/mycourse/MyNoticeWrite.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "myclass/inquiry",
            name: "강의 - 강의 문의사항",
            component: () =>
              import("@/components/lms/sub/mycourse/MyInquiryList.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "myclass/inquiry/view",
            name: "강의 - 강의 문의사항 - 상세",
            component: () =>
              import("@/components/lms/sub/mycourse/MyInquiryView.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "myclass/inquiry/write",
            name: "강의 - 강의 문의사항 - 쓰기",
            component: () =>
              import("@/components/lms/sub/mycourse/MyInquiryWrite.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "mygrades",
            name: "강의 - 내 성적",
            component: () =>
              import("@/components/lms/sub/mycourse/MyGrades.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "mygrades-appeal/:scoreId",
            name: "강의 - 내 성적 - 이의신청",
            component: () =>
              import("@/components/lms/sub/mycourse/MyGradesAppeal.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "mygrades/view/:lectureId",
            name: "강의 - 내 성적 - 상세보기",
            component: () =>
              import("@/components/lms/sub/mycourse/MyGradesView.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "mygrades-response/:scoreId",
            name: "강의 - 내 성적 - 답변",
            component: () =>
              import("@/components/lms/sub/mycourse/MyGradesResponse.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "custom",
            name: "맞춤 수강추천",
            component: () => import("@/components/lms/sub/ReadyComp.vue"),
            meta: {
              roles: [
                "ROLE_STUDENT",
                "ROLE_PROFESSOR",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "badge",
            name: "디지털배지 조회 - 목록",
            component: () =>
              import("@/components/lms/sub/badge/DigitalBadges.vue"),
            meta: {
              roles: [
                "ROLE_STUDENT",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            // path: 'badge/view/:badgeId',
            path: "badge/view",
            name: "디지털배지 조회 - 상세",
            component: () =>
              import("@/components/lms/sub/badge/DigitalBadgeView.vue"),
            meta: {
              roles: [
                "ROLE_STUDENT",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "b-apply",
            name: "디지털배지 신청 - 목록",
            component: () =>
              import("@/components/lms/sub/badge/DigitalBadgesApply.vue"),
            meta: {
              roles: [
                "ROLE_STUDENT",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "exam",
            name: "시험관리 - 과목 리스트",
            component: () => import("@/components/lms/sub/exam/CourseList.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "exam/list/:lectureId/:examId",
            name: "시험관리 - 학생 리스트",
            component: () =>
              import("@/components/lms/sub/exam/StudentList.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "exam/marking/:lectureId/:examId:/:userId",
            name: "시험관리 - 채점",
            component: () =>
              import("@/components/lms/sub/exam/ExamGrading.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "my-exam",
            name: "시험 - 목록",
            component: () => import("@/components/lms/sub/exam/MyExamList.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "check",
            name: "시험 - 결과 확인 - 목록",
            component: () =>
              import("@/components/lms/sub/exam/MyCheckList.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "check/view/:examId/:scoreId",
            name: "시험 - 결과 확인 - 상세",
            component: () => import("@/components/lms/sub/exam/MyExamView.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "course",
            name: "강의관리 - 강의조회",
            component: () =>
              import("@/components/lms/sub/course/CourseList.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "course/subject/:gubun",
            name: "강의관리 - 강의수정 - 과목정보 등록",
            component: () =>
              import("@/components/lms/sub/course/CourseSubject.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "course/subject/:gubun/:lectureId",
            name: "강의관리 - 강의수정 - 과목정보 수정",
            component: () =>
              import("@/components/lms/sub/course/CourseSubject.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "course/lecture/:gubun",
            name: "강의관리 - 강의수정 - 강의정보 등록",
            component: () =>
              import("@/components/lms/sub/course/CourseLecture.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "course/lecture/:gubun/:lectureId",
            name: "강의관리 - 강의수정 - 강의정보 수정",
            component: () =>
              import("@/components/lms/sub/course/CourseLecture.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "course/weekly/:gubun",
            name: "강의관리 - 강의수정 - 주차별 강의상세 등록",
            component: () =>
              import("@/components/lms/sub/course/CourseWeekly.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "course/weekly/:gubun/:lectureId",
            name: "강의관리 - 강의수정 - 주차별 강의상세 수정",
            component: () =>
              import("@/components/lms/sub/course/CourseWeekly.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "course/syllabus/:gubun",
            name: "강의관리 - 강의수정 - 강의계획서 업로드 등록",
            component: () =>
              import("@/components/lms/sub/course/CourseSyllabus.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "course/syllabus/:gubun/:lectureId",
            name: "강의관리 - 강의수정 - 강의계획서 업로드 수정",
            component: () =>
              import("@/components/lms/sub/course/CourseSyllabus.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          // {
          // 	path: 'contents',
          // 	name: '강의관리 - 컨텐츠 관리',
          // 	component: () => import('@/components/lms/sub/course/CourseContsList.vue'),
          // 	meta: {
          // 		roles : ['ROLE_PROFESSOR', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
          // 	}
          // },

          {
            path: "sapl",
            name: "강의관리 - 수강 관리",
            component: () =>
              import("@/components/lms/sub/course/CourseSaplList.vue"),
            meta: {
              roles: ["ROLE_PROFESSOR", "ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "q-bank",
            name: "강의관리 - 컨텐츠 관리",
            component: () =>
              import("@/components/lms/sub/course/CourseQbank.vue"),
            meta: {
              roles: ["ROLE_PROFESSOR", "ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "q-bank/list/:lectureId",
            name: "강의관리 - 컨텐츠 문제은행 관리",
            component: () =>
              import("@/components/lms/sub/course/CourseQbankList.vue"),
            meta: {
              roles: ["ROLE_PROFESSOR", "ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "q-bank/write/:lectureId/:examQuestionId",
            name: "강의관리 - 컨텐츠 문제은행 관리 - 등록",
            component: () =>
              import("@/components/lms/sub/course/CourseQbankWrite.vue"),
            meta: {
              roles: ["ROLE_PROFESSOR", "ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "svy/template",
            name: " 설문관리 - 설문템플릿관리",
            component: () =>
              import("@/components/lms/sub/survey/TemplateList.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "svy/template/write",
            name: " 설문관리 - 설문템플릿관리 - 등록",
            component: () =>
              import("@/components/lms/sub/survey/TemplateWrite.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "survey",
            name: "설문관리 - 설문지 관리",
            component: () =>
              import("@/components/lms/sub/survey/SurveyList.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "survey/info",
            name: "설문관리 - 설문지 관리 - 설문지 정보",
            component: () =>
              import("@/components/lms/sub/survey/SurveyInfo.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "survey/ques",
            name: "설문관리 - 설문지 관리 - 문항 목록",
            component: () =>
              import("@/components/lms/sub/survey/SurveyQues.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "eval",
            name: "성적관리 - 평가 관리",
            component: () =>
              import("@/components/lms/sub/grade/CourseEval.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },

          {
            path: "eval/write/:lectureId",
            name: "성적관리 - 평가 관리 - 평가비율관리",
            component: () =>
              import("@/components/lms/sub/grade/CourseEvalWrite.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "eval/date/:lectureId",
            name: "성적관리 - 평가 관리 - 시험기간관리",
            component: () =>
              import("@/components/lms/sub/grade/CourseEvalDate.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "eval/view/:lectureId",
            name: "성적관리 - 평가 관리 - 조회하기",
            component: () =>
              import("@/components/lms/sub/grade/CourseEvalView.vue"),
            meta: {
              roles: [
                "ROLE_PROFESSOR",
                "ROLE_ADMIN",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "grade",
            name: "성적관리 - 강의 목록",
            component: () => import("@/components/lms/sub/grade/GradeList.vue"),
            meta: {
              roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },

          {
            path: "sgra",
            name: "성적관리 - 성적조회(테스트)",
            component: () =>
              import("@/components/lms/sub/grade/GradeSgraList.vue"),
            meta: {
              roles: ["ROLE_PROFESSOR", "ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },

          {
            path: "appeal/write/:scoreId",
            name: "성적관리 - 답변 쓰기",
            component: () =>
              import("@/components/lms/sub/grade/GradeAppealWrite.vue"),
            meta: {
              roles: [
                "ROLE_ADMIN",
                "ROLE_PROFESSOR",
                "ROLE_SUPER_ADMIN",
                "ROLE_SUPER",
              ],
            },
          },
          {
            path: "grade/students/:lectureId",
            name: "성적관리 - 성적 조회",
            component: () =>
              import("@/components/lms/sub/grade/GradeStudentsList.vue"),
            meta: {
              roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },

          {
            path: "grade/professor/students/:lectureId",
            name: "성적관리 - 성적 입력",
            component: () =>
              import(
                "@/components/lms/sub/grade/GradeProfessorStudentList.vue"
              ),
            meta: {
              roles: ["ROLE_PROFESSOR", "ROLE_SUPER"],
            },
          },
          {
            path: "grade/professor",
            name: "성적관리 - 강의 목록(교수)",
            component: () =>
              import("@/components/lms/sub/grade/GradeProfessorList.vue"),
            meta: {
              roles: ["ROLE_PROFESSOR", "ROLE_SUPER"],
            },
          },
          {
            path: "appeal",
            name: "성적관리 - 이의신청",
            component: () =>
              import("@/components/lms/sub/grade/GradeAppeal.vue"),
            meta: {
              roles: ["ROLE_ADMIN", "ROLE_PROFESSOR", "ROLE_SUPER_ADMIN"],
            },
          },
          //원복
          {
            path: "grade/svy-response",
            name: "성적관리 - 설문정보",
            component: () =>
              import("@/components/lms/sub/grade/GradeSurvey.vue"),
            meta: {
              roles: ["ROLE_ADMIN", "ROLE_PROFESSOR", "ROLE_SUPER_ADMIN"],
            },
          },
          {
            path: "appeal/list/:lectureId",
            name: "성적관리 - 상세 목록",
            component: () =>
              import("@/components/lms/sub/grade/GradeAppealList.vue"),
            meta: {
              roles: ["ROLE_ADMIN", "ROLE_PROFESSOR", "ROLE_SUPER_ADMIN"],
            },
          },
          {
            path: "contents/write",
            name: "강의관리 - 컨텐츠 관리 - 컨텐츠 등록",
            component: () =>
              import("@/components/lms/sub/course/CourseContsWrite.vue"),
            meta: {
              roles: ["ROLE_PROFESSOR", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
            },
          },
          {
            path: "contents/detail/list",
            name: "강의관리 - 컨텐츠 강의 관리",
            component: () =>
              import("@/components/lms/sub/course/CourseDetailList.vue"),
            meta: {
              roles: ["ROLE_PROFESSOR", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
            },
          },
          {
            path: "contents/detail/write",
            name: "강의관리 - 컨텐츠 강의 관리 - 등록",
            component: () =>
              import("@/components/lms/sub/course/CourseDetailWrite.vue"),
            meta: {
              roles: ["ROLE_PROFESSOR", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
            },
          },

          {
            path: "attn",
            name: "출석관리 - 리스트",
            component: () =>
              import("@/components/lms/sub/attendance/AttnList.vue"),
            meta: {
              roles: ["ROLE_ADMIN", "ROLE_PROFESSOR", "ROLE_SUPER_ADMIN"],
            },
          },
          {
            path: "attn/status/:lectureId",
            name: "출석관리 - 출결현황",
            component: () =>
              import("@/components/lms/sub/attendance/AttnStatus.vue"),
            meta: {
              roles: ["ROLE_ADMIN", "ROLE_PROFESSOR", "ROLE_SUPER_ADMIN"],
            },
          },
          {
            path: "myclass/attn/:lectureId",
            name: "출석관리 - 학생 출석 확인",
            component: () =>
              import("@/components/lms/sub/mycourse/MyClassAttn.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          {
            path: "member",
            name: "회원관리",
            component: () =>
              import("@/components/lms/sub/member/MemberList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "notice",
            name: "커뮤니티관리 - 게시판관리 - 공지사항",
            component: () => import("@/components/lms/board/NoticeList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "notice/:gubun",
            name: "커뮤니티관리 - 게시판관리 - 공지사항 - 등록",
            component: () => import("@/components/lms/board/NoticeWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "notice/:gubun/:noticeId",
            name: "커뮤니티관리 - 게시판관리 - 공지사항 - 수정",
            component: () => import("@/components/lms/board/NoticeWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "notice/view/:noticeId",
            name: "커뮤니티관리 - 게시판관리 - 공지사항 - 상세",
            component: () => import("@/components/lms/board/NoticeView.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "dataroom",
            name: "커뮤니티관리 - 게시판관리 - 자료실",
            component: () => import("@/components/lms/board/DataroomList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "dataroom/:gubun",
            name: "커뮤니티관리 - 게시판관리 - 자료실 - 등록",
            component: () => import("@/components/lms/board/DataroomWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "dataroom/:gubun/:archivesId",
            name: "커뮤니티관리 - 게시판관리 - 자료실 - 수정",
            component: () => import("@/components/lms/board/DataroomWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "dataroom/view/:archivesId",
            name: "커뮤니티관리 - 게시판관리 - 자료실 - 보기",
            component: () => import("@/components/lms/board/DataroomView.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "qna",
            name: "커뮤니티관리 - 게시판관리 - Q&A",
            component: () => import("@/components/lms/board/QnaList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "qna/:gubun",
            name: "커뮤니티관리 - 게시판관리 - Q&A - 등록",
            component: () => import("@/components/lms/board/QnaWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "qna/:gubun/:qnaId",
            name: "커뮤니티관리 - 게시판관리 - Q&A - 수정",
            component: () => import("@/components/lms/board/QnaWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "qna/view/:qnaId",
            name: "커뮤니티관리 - 게시판관리 - Q&A - 상세",
            component: () => import("@/components/lms/board/QnaView.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "lecture-notice",
            name: "커뮤니티관리 - 게시판관리 - 강의공지사항",
            component: () =>
              import("@/components/lms/board/LectureNoticeList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "lecture-notice/write",
            name: "커뮤니티관리 - 게시판관리 - 강의공지사항 - 쓰기",
            component: () =>
              import("@/components/lms/board/LectureNoticeWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "lecture-notice/view",
            name: "커뮤니티관리 - 게시판관리 - 강의공지사항 - 상세",
            component: () =>
              import("@/components/lms/board/LectureNoticeView.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "lecture-inquiry",
            name: "커뮤니티관리 - 게시판관리 - 강의문의사항",
            component: () =>
              import("@/components/lms/board/LectureInquiryList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "lecture-inquiry/write",
            name: "커뮤니티관리 - 게시판관리 - 강의문의사항 - 쓰기",
            component: () =>
              import("@/components/lms/board/LectureInquiryWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "lecture-inquiry/view",
            name: "커뮤니티관리 - 게시판관리 - 강의문의사항 - 상세",
            component: () =>
              import("@/components/lms/board/LectureInquiryView.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "menu",
            name: "메뉴관리",
            component: () => import("@/components/lms/sub/menu/MenuList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "menu/reg",
            name: "메뉴관리 - 메뉴등록",
            component: () => import("@/components/lms/sub/menu/MenuReg.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "statistics",
            name: "통계관리",
            component: () =>
              import("@/components/lms/sub/statistics/StatsList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "system",
            name: "로그관리 - 시스템관리",
            component: () =>
              import("@/components/lms/sub/system/SystemMgmt.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "pms",
            name: "사업비관리시스템",
            component: () => import("@/components/lms/sub/pms/PmsList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          // 임시 페이지 추가 화면 학생 계정 로그인시 2024.06 KPanda
          {
            path: "issuance",
            name: "배지 신청전 수강과목조회(임시)",
            component: () =>
              import("@/components/lms/sub/issuance/IssuanceList.vue"),
            meta: {
              roles: ["ROLE_STUDENT"],
            },
          },
          // Kpanda 2024.07 교직원 계정 로그인시
          {
            path: "issuance/rols",
            name: "교직원 - 배지 발급 신청",
            component: () =>
              import("@/components/lms/sub/issuance/IssuanRols.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SUPER"],
            },
          },

          //PMS 이전 작업 페이지
          {
            path: "pms/disb",
            name: "사업비관리시스템 - 집행등록",
            component: () =>
              import("@/components/lms/sub/pms/DisbursementList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "pms/disb/mgmt",
            name: "사업비관리시스템 - 집행등록 - 지출관리",
            component: () =>
              import("@/components/lms/sub/pms/DisbursementMgmt.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "pms/disb/mgmt/write",
            name: "사업비관리시스템 - 집행등록 - 지출관리 - 등록",
            component: () =>
              import("@/components/lms/sub/pms/DisbursementWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "pms/sett",
            name: "사업비관리시스템 - 전자정산",
            component: () =>
              import("@/components/lms/sub/pms/SettlementList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "pms/sett/view",
            name: "사업비관리시스템 - 전자정산 - 상세페이지",
            component: () =>
              import("@/components/lms/sub/pms/SettlementView.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "pms/sett/report",
            name: "사업비관리시스템 - 전자정산 - 사용실적",
            component: () => import("@/components/lms/sub/pms/PmsReport.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "pms/sett/total",
            name: "사업비관리시스템 - 전자정산 - 반납내역",
            component: () => import("@/components/lms/sub/pms/PmsTotal.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },

          {
            path: "site/popup",
            name: "사이트관리 - 팝업관리",
            component: () => import("@/components/lms/sub/site/PopupList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "site/popup/write",
            name: "사이트관리 - 팝업관리 - 등록",
            component: () => import("@/components/lms/sub/site/PopupWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "site/event",
            name: "사이트관리 - 이벤트관리",
            component: () => import("@/components/lms/sub/site/EventList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "site/event/write",
            name: "사이트관리 - 이벤트관리 - 등록",
            component: () => import("@/components/lms/sub/site/EventWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          // {
          // 	path: 'performance/list',
          // 	name: '성과관리',
          // 	component: () => import('@/components/lms/sub/performance/PerfList.vue'),
          // 	meta: {
          // 		roles : ['ROLE_SUPER_ADMIN']
          // 	}
          // },
          {
            path: "performance/yearly",
            name: "성과관리 - 년도별 리스트",
            component: () =>
              import("@/components/lms/sub/performance/YearlyList.vue"),
          },
          // {
          // 	path: 'performance/detail/',
          // 	name: '성과관리 - 국민대 > 핵심 리스트',
          // 	component: () => import('@/components/lms/sub/performance/CoreList.vue'),
          // 	meta: { roles: ['ROLE_SUPER_ADMIN','ROLE_SUPER']}
          // },
          // {
          // 	path: 'performance/detail/kmu/self',
          // 	name: '성과관리 - 국민대 > 자율 리스트',
          // 	component: () => import('@/components/lms/sub/performance/SelfList.vue'),
          // 	meta: {roles: ['ROLE_SUPER_ADMIN','ROLE_SUPER']}
          // },
          {
            path: "performance/detail/:university",
            name: "성과관리 - 대학별 > 핵심 리스트",
            component: () =>
              import("@/components/lms/sub/performance/CoreList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "performance/detail/:university/self",
            name: "성과관리 - 대학별 > 자율 리스트",
            component: () =>
              import("@/components/lms/sub/performance/SelfList.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "performance/detail/:university/:performanceId",
            name: "성과관리 - 대학별 상세",
            component: () =>
              import("@/components/lms/sub/performance/PerfDetail.vue"),
            meta: { roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"] },
          },
          // {
          // 	path: 'performance/:gubun',
          // 	name: '성과관리 - 등록',
          // 	component: () => import('@/components/lms/sub/performance/PerfWrite.vue'),
          // 	meta: {
          // 		roles : ['ROLE_SUPER_ADMIN']
          // 	}
          // },
          {
            path: "performance/detail/update/:performanceId",
            name: "성과관리 - 수정",
            component: () =>
              import("@/components/lms/sub/performance/PerfWrite.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "performance/detail/edit",
            name: "성과관리 - 전체 등록 & 수정",
            component: () =>
              import("@/components/lms/sub/performance/PerfEdit.vue"),
            meta: {
              roles: ["ROLE_SUPER_ADMIN", "ROLE_SUPER"],
            },
          },
          {
            path: "lms/attn/status/:lectureId",
            name: "출결관리",
            component: () =>
              import("@/components/lms/sub/attendance/StudentsList.vue"),
            meta: {
              roles: ["ROLE_ADMIN", "ROLE_PROPESSER", "ROLE_SUPER"],
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 5);
      });
    } else {
      return { top: 0 };
    }
  },
});

function enterFullscreen() {
  document.documentElement.requestFullscreen();
}
function exitFullscreen() {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  }
}

router.beforeEach((to, from, next) => {
  // console.log("to.matched  : " + to.matched[0]); // Logs the matched routes
  const { cookies } = useCookies();
  let superAdmin = "";
  let role = "";
  let roleStatus = "";
  let roleSuperStatus = "";
  const userInfo = cookies.get("userInfo");
  const store = useStore();

  if (to.meta.fullscreen) {
    enterFullscreen();
  } else {
    exitFullscreen();
  }

  if (userInfo != null && userInfo != undefined && userInfo != "") {
    // alert("로그인 했다 :: "  + userInfo.admin + "userInfo.socpsCd :   " +userInfo.socpsCd);
    //교직원일때 superAdmin =true
    superAdmin = userInfo.admin;
    role = userInfo.socpsCd;
  } else {
    if (to.matched[0].name == "viewMain") {
      if (userInfo != null && userInfo != undefined && userInfo != "") {
        cookies.remove("userInfo");
      }
      return next();
    } else {
      if (
        userInfo == null ||
        userInfo == undefined ||
        userInfo == "" ||
        userInfo.length <= 0
      ) {
        store.dispatch("openPopup", { id: "popupNoLogin", data: "/" });
      } else {
        return (
          next("/"),
          alert("권한이 등록되지 않았습니다. 관리자에게 문의해주세요.")
        );
      }
    }
  }

  if (superAdmin == true) {
    roleSuperStatus = "ROLE_SUPER_ADMIN";
  }
  if (role == "00") {
    roleStatus = "ROLE_SUPER";
  }
  if (role == "02") {
    roleStatus = "ROLE_ADMIN";
  }
  if (role == "01") {
    roleStatus = "ROLE_PROFESSOR";
  }
  if (role == "05") {
    roleStatus = "ROLE_STUDENT";
  }
  if (roleStatus == "") {
    if (
      userInfo == null ||
      userInfo == undefined ||
      userInfo == "" ||
      userInfo.length <= 0
    ) {
      return store.dispatch("openPopup", { id: "popupNoLogin", data: "/" });
    }
  }

  if (!window.history.state || !window.history.state.scroll) {
    window.scrollTo(0, 0);
  }

  if (!window.history.state || !window.history.state.scroll) {
    window.scrollTo(0, 0);
  }

  // ROLE_ADMIN => 02 | 교직원
  // ROLE_PROFESSOR => 01 | 교수
  // ROLE_STUDENT => 05 | 학생
  if (to.matched[0].name == "viewMain") {
    if (userInfo != null && userInfo != undefined && userInfo != "") {
      cookies.remove("userInfo");
    }
    return next();
  } else {
    if (
      (to.meta.roles && to.meta.roles.includes(roleStatus)) ||
      (to.meta.roles && to.meta.roles.includes(roleSuperStatus))
    ) {
      getAuthAPI(userInfo.userSeq).then((response) => {
        if (userInfo.admin != response.data) {
          if (response.data == true) {
            userInfo.admin = true;
            //ROLE_SUPER_ADMIN
            roleSuperStatus = "ROLE_SUPER_ADMIN";
            // roleStatus = "ROLE_ADMIN";
            cookies.remove("userInfo");
            cookies.set("userInfo", userInfo);
          } else {
            userInfo.admin = false;
            roleSuperStatus = "";
            cookies.remove("userInfo");
            cookies.set("userInfo", userInfo);
          }
        }
      });

      if (to.path == "/lms/myclass" || !to.path.startsWith("/lms/myclass")) {
        sessionStorage.removeItem("myclassLectureId");
        sessionStorage.removeItem("myclassLectureNm");
      }
      return next();
    } else {
      if (
        userInfo == null ||
        userInfo == undefined ||
        userInfo == "" ||
        userInfo.length <= 0
      ) {
        // alert("코드 분석줄 2024.05.05 ")
        return store.dispatch("openPopup", { id: "popupNoLogin", data: "/" });
      } else {
        return (
          next("/lms"),
          alert("권한이 등록되지 않았습니다.관리자에게 문의해주세요.")
        );
      }
    }
  }
});

router.afterEach((to) => {
  const currentPath = to.path;
  const navItems = document.querySelectorAll(".gnb .nav-item");
  navItems.forEach((item) => {
    if (item.getAttribute("link") === currentPath) {
      item.classList.add("on");
    } else {
      item.classList.remove("on");
    }
  });
});
export { router };
